// External
import axios from "axios";
import React, { Suspense, lazy, useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";

import User from "./Types/User";
import UserResponse from "./Types/UserResponse";

const FrontPage = lazy(() => import("./Pages/FrontPage/FrontPage"));
const FrontPageEditor = lazy(() => import("./Pages/FrontPage/FrontPageEditor/FrontPageEditor"));
const GalleryOverview = lazy(() => import("./Pages/Gallery/GalleryOverview/GalleryOverview"));
const GalleryOverviewEditor = lazy(() => import("./Pages/Gallery/GalleryOverviewEditor/GalleryOverviewEditor"));
const GalleryPage = lazy(() => import("./Pages/Gallery/GalleryPage/GalleryPage"));
const GalleryEditor = lazy(() => import("./Pages/Gallery/GalleryEditor/GalleryEditor"));
const Headerbar = lazy(() => import("./Components/Headerbar"));
const ImagePage = lazy(() => import("./Pages/Gallery/ImagePage/ImagePage"));
const UsersPage = lazy(() => import("./Pages/Gallery/UsersPage/UsersPage"));

const App = () => {
    const [getUser, setGetUser] = useState<boolean>(true);
    const [user, setUser] = useState<User | undefined>();
    const [backEndError, setBackEndError] = useState<boolean>(false);

    useEffect(() => {
        if(!getUser)
            return;

        axios.get(process.env.REACT_APP_API_URL + "/user", { withCredentials: true })
            .then((response: UserResponse) => {
                setUser(response.data ? response.data : undefined);
                setBackEndError(false);
                setGetUser(false);
            })
            .catch((error) => {
                // TODO metric
                // eslint-disable-next-line no-console
                console.log(error);
                setBackEndError(true);
                setGetUser(false);
            });
    }, [getUser]);

    const onLoginOut = () => {
        setGetUser(true);
    };

    if(getUser)
        return <div />;

    if(backEndError)
        return (
            <div className="App">
                {backEndError &&
                <h3>An error has occured, Gallery mode unavailable, please contact support if this error persists.</h3>}
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route
                            path="/"
                            element={(
                                <div>
                                    <Headerbar backButtonLink={""} />
                                    <FrontPage />
                                </div>
                            )}
                        />
                    </Routes>
                </Suspense>
            </div>
        );

    return (
        <div className="App">
            <Suspense fallback={<div />}>
                <Routes>
                    <Route path="/gallery/:id/:imageid" element={<ImagePage />} />
                    <Route
                        path="/editgalleries/"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/gallery"} user={user} onLoginOut={onLoginOut} />
                                <GalleryOverviewEditor />
                            </div>
                        )}
                    />
                    <Route
                        path="/editgallery/"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/gallery"} user={user} onLoginOut={onLoginOut} />
                                <GalleryEditor />
                            </div>
                        )}
                    />
                    <Route
                        path="/editgallery/:id"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/gallery"} user={user} onLoginOut={onLoginOut} isGalleryEditPage />
                                <GalleryEditor />
                            </div>
                        )}
                    />
                    <Route
                        path="/gallery/:id"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/gallery"} user={user} onLoginOut={onLoginOut} isGalleryPage />
                                <GalleryPage user={user} />
                            </div>
                        )}
                    />
                    <Route
                        path="/gallery"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/"} user={user} onLoginOut={onLoginOut} isGalleryOverview />
                                <GalleryOverview user={user} />
                            </div>
                        )}
                    />
                    <Route
                        path="/users"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/gallery"} user={user} onLoginOut={onLoginOut} />
                                <UsersPage />
                            </div>
                        )}
                    />
                    <Route
                        path="/editfrontpage"
                        element={(
                            <div>
                                <Headerbar backButtonLink={"/"} user={user} onLoginOut={onLoginOut} />
                                <FrontPageEditor />
                            </div>
                        )}
                    />
                    <Route
                        path="/"
                        element={(
                            <div>
                                <Headerbar backButtonLink={""} user={user} isFrontPage onLoginOut={onLoginOut} />
                                <FrontPage />
                            </div>
                        )}
                    />
                </Routes>
            </Suspense>
        </div>
    );
};

export default App;
