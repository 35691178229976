import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./i18n/en.json";
import translationDE from "./i18n/de.json";
import translationJP from "./i18n/jp.json";
import translationFR from "./i18n/fr.json";
import translationES from "./i18n/es.json";
import translationCH from "./i18n/ch.json";
import translationCustomEN from "./customcontent/i18n/en.json";
import translationCustomDE from "./customcontent/i18n/de.json";
import translationCustomJP from "./customcontent/i18n/jp.json";
import translationCustomFR from "./customcontent/i18n/fr.json";
import translationCustomES from "./customcontent/i18n/es.json";
import translationCustomCH from "./customcontent/i18n/ch.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        global: translationEN,
        custom: translationCustomEN,
    },
    de: {
        global: translationDE,
        custom: translationCustomDE,
    },
    jp: {
        global: translationJP,
        custom: translationCustomJP,
    },
    fr: {
        global: translationFR,
        custom: translationCustomFR,
    },
    es: {
        global: translationES,
        custom: translationCustomES,
    },
    ch: {
        global: translationCH,
        custom: translationCustomCH,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            // cache user language on
            caches: ["localStorage", "cookie"],
        },
        fallbackLng: "en",
        resources,
        keySeparator: false, // we do not use keys in form messages.welcome
        // react already safe from xss
        interpolation: { escapeValue: false },
        ns: ["global", "custom", "moduleB"],
        defaultNS: "global",
    });

export default i18n;
